<script>
	import { giftStore } from '../../Store/game.store';
	import { fade } from 'svelte/transition';

	import Thumbnail from '../Shared/Thumbnail.svelte';

	function getGiftClass(stealCount) {
		if (stealCount === 3) {
			return ' -gone';
		}
		return '';
	}
</script>

<div class="gift-list-container">
	{#each $giftStore as { id, imageUrl, description, stealCount, owner }, i}
		<div class={`gift-box${getGiftClass(stealCount)}`} transition:fade={{ duration: 500 }}>
			<Thumbnail {imageUrl} alt={description} />
			<div>
				<h3>{description}</h3>
				<p>{owner?.nickname} has it</p>
				<p>Steals: {stealCount}</p>
			</div>
		</div>
	{/each}
</div>

{#if $giftStore.length === 0}
	<p>Open something!</p>
{/if}

<style>
	.gift-list-container {
		display: flex;
		flex-direction: column-reverse;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0 auto;
	}
	.gift-box {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		margin: 1rem;
		width: 100%;

		h3 {
			margin-left: 1em;
			text-align: left;
		}

		> div {
			width: 100%;
		}
	}

	.gift-box.-gone {
		background: #eee;
	}
</style>
