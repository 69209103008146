<script>
	import { playerStore } from '../../Store/game.store';
	import { fade } from 'svelte/transition';
</script>

<ul>
	{#each $playerStore as { id, name }, i}
		<li transition:fade>{name}</li>
	{/each}
</ul>

<style lang="postcss">
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column-reverse;
	}
</style>
