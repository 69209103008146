function getMediaStream(constraints = { video: true }) {
	return navigator.mediaDevices.getUserMedia(constraints);
}

function getImageCapture(mediaStream) {
	try {
		const track = mediaStream.getVideoTracks()[0];
		return new ImageCapture(track);
	} catch {
		return null;
	}
}

export { getMediaStream, getImageCapture };
