import Nes from "@hapi/nes/lib/client";

const host = !process.env.isProd
  ? "ws://localhost:8000"
  : "wss://api.grabbagger.shipb.us";

export function watchPlayers(handler, gameId) {
  const client = new Nes.Client(host);
  const start = async () => {
    await client.connect();

    client.subscribe(`/api/players/${gameId}`, handler);
  };
  start();
}

export function watchTurns(handler, gameId) {
  const client = new Nes.Client(host);
  const start = async () => {
    await client.connect();

    client.subscribe(`/api/turns/${gameId}`, handler);
  };
  start();
}

export function watchGifts(handler, gameId) {
  const client = new Nes.Client(host);
  const start = async () => {
    await client.connect();

    client.subscribe(`/api/gifts/${gameId}`, handler);
  };
  start();
}
