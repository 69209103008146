<script>
	export let navigate;
</script>

<ul>
	<li>
		<a
			on:click|preventDefault={() => {
				navigate('gifts');
			}}
			href="#">Gifts</a
		>
	</li>
	<li>
		<a
			on:click|preventDefault={() => {
				navigate('turns');
			}}
			href="#">Turns</a
		>
	</li>
	<li>
		<a
			on:click|preventDefault={() => {
				navigate('players');
			}}
			href="#">Players</a
		>
	</li>
</ul>

<style lang="postcss">
	ul {
		list-style: none;
		padding: 0;
		display: flex;
		justify-content: space-evenly;
	}
</style>
