<script>
	import { onMount } from 'svelte';
	import { initGame } from '../Store/game.store';

	import { fade, blur, slide } from 'svelte/transition';

	import GiftList from './Gifts/List.svelte';
	import PlayerList from './Players/List.svelte';
	import TurnsList from './Turns/List.svelte';
	import CurrentTurn from './Turns/Current.svelte';
	import Menu from './Shared/Menu.svelte';

	export let id;
	export let show = 'gifts';
	export let animationDuration = 400;

	function updateShow(setShow) {
		show = setShow;
	}

	onMount(() => {
		initGame(id);
	});
</script>

<div>The Game: {id}</div>
<div><CurrentTurn /></div>
<div>
	<Menu navigate={updateShow} />
</div>
{#if show === 'gifts'}
	<div
		in:fade={{ duration: animationDuration, delay: animationDuration }}
		out:blur={{ duration: animationDuration, delay: 0 }}
	>
		<h1>Gifts</h1>
		<GiftList />
	</div>
{:else if show === 'players'}
	<div
		in:fade={{ duration: animationDuration, delay: animationDuration }}
		out:blur={{ duration: animationDuration, delay: 0 }}
	>
		<h1>Players</h1>
		<PlayerList />
	</div>
{:else if show === 'turns'}
	<div
		in:fade={{ duration: animationDuration, delay: animationDuration }}
		out:blur={{ duration: animationDuration, delay: 0 }}
	>
		<h1>Turns</h1>
		<TurnsList />
	</div>
{/if}

<style lang="postcss">
</style>
