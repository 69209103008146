//const api = "https://api.grabbagger.shipb.us";
const api = "/api";

export function getPlayers(gameId) {
  return fetch(`${api}/players/${gameId}`).then((response) => {
    return response.json();
  });
}

export function registerPlayer(playerInfo, gameId) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...playerInfo,
      gameId,
    }),
  };
  return fetch(`${api}/players/${gameId}`, options).then((response) => {
    return response.json();
  });
}

export function shufflePlayers(gameId) {
  return fetch(`${api}/shuffleplayers/${gameId}`).then((response) => {
    return response.json();
  });
}

export function getGame(gameId) {
  return fetch(`${api}/games/${gameId}`).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    return false;
  });
}

export function addGame(gameId, name) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: gameId,
      name,
    }),
  };
  return fetch(`${api}/games`, options).then((response) => {
    return response.json();
  });
}

export function getTurnInfo(gameId) {
  return fetch(`${api}/turns/${gameId}`).then((response) => {
    return response.json();
  });
}

export function getGifts(gameId) {
  return fetch(`${api}/gifts/${gameId}`).then((response) => {
    return response.json();
  });
}

export function openGift(gameId, giftDescription, playerId) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      giftDescription,
      playerId,
      action: 0,
    }),
  };
  return fetch(`${api}/turns/${gameId}`, options).then((response) => {
    return response.json();
  });
}

export function updateGift(gameId, giftInfo) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(giftInfo),
  };
  return fetch(`${api}/gifts/${gameId}`, options).then((response) => {
    return response.json();
  });
}

export function stealGift(gameId, giftId, playerId) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      giftId,
      playerId,
      action: 1,
    }),
  };
  return fetch(`${api}/turns/${gameId}`, options).then((response) => {
    return response.json();
  });
}

export function undoTurn(gameId) {
  const options = {
    method: "DELETE",
  };
  return fetch(`${api}/turns/${gameId}`, options).then((response) => {
    return response.json();
  });
}

export function startGame(gameId) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      isStarted: true,
    }),
  };
  return fetch(`${api}/startgame/${gameId}`, options).then((response) => {
    return response.json();
  });
}

export function uploadImage(gameId, giftId, file) {
  let data = new FormData();
  data.append("giftImage", file);
  // data.append("name", giftId + ".png");

  const options = {
    method: "POST",
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded"
    // },
    body: data,
  };
  return fetch(`${api}/giftimage/${gameId}/${giftId}`, options).then(
    (response) => {
      return response.json();
    }
  );
}
