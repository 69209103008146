<script>
	import { fade, scale } from 'svelte/transition';

	export let imageUrl;
	export let alt;
	export let showPreview = false;
	function showLarge() {
		showPreview = !showPreview;
	}
</script>

{#if imageUrl}
	{#if showPreview}<img
			transition:scale
			class="preview"
			on:click={showLarge}
			src={imageUrl}
			{alt}
		/>
		<div on:click={showLarge} transition:fade class="overlay" />
	{/if}
	<img transition:scale class="thumb" on:click={showLarge} src={imageUrl} {alt} />
{/if}

<style>
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
		z-index: 10;
	}

	img.thumb {
		max-height: 100px;
	}

	img.preview {
		max-height: 100vh;
		max-width: 90vw;
		position: fixed;
		top: 10%;
		left: 5%;
		z-index: 20;
	}
</style>
