<script>
	import { onMount } from 'svelte';
	import CameraCapture from './CameraPreview.svelte';
	import { getMediaStream, getImageCapture } from '../../lib/camera';
	import { uploadImage } from '../../lib/api';
	var constraints = { video: { facingMode: 'environment' }, audio: false };

	const cameraWidth = 500;
	const cameraHeight = 500;

	let pictureUrl = null;
	let imageCapture = null;
	let mediaStreamPromise = null;
	let currentBlob = null;

	export let giftId;
	export let id;

	onMount(() => {
		mediaStreamPromise = getMediaStream(constraints).then((mediaStream) => {
			imageCapture = getImageCapture(mediaStream);
			return mediaStream;
		});
	});

	function takePicture() {
		if (imageCapture) {
			imageCapture
				.takePhoto()
				.then((blob) => {
					currentBlob = blob;
					pictureUrl = URL.createObjectURL(blob);
				})
				.catch((err) => console.log('Error while taking photo ', err));
		} else {
			mediaStream;
		}
	}

	async function savePicture() {
		if (currentBlob) {
			try {
				await uploadImage(id, giftId, currentBlob);
				window.location = `/admin/${id}`;
			} catch (err) {
				console.log(err);
			}
		}
	}

	async function nopePicture() {
		currentBlob = null;
		pictureUrl = null;
	}
</script>

<div class="camera">
	<div class="camera-video">
		{#await mediaStreamPromise}
			<h1>Waiting for camera....</h1>
		{:then mediaStream}
			<CameraCapture {mediaStream} previewWidth={cameraWidth} previewHeight={cameraHeight} />
		{:catch error}
			<h1>Error: {error.message}</h1>
		{/await}

		{#if pictureUrl}
			<div class="taken-picture-container">
				<img
					class="taken-picture"
					src={pictureUrl}
					alt="result"
					height={cameraHeight}
					width={cameraWidth}
				/>
				<button on:click={savePicture}>Yep</button>
				<button on:click={nopePicture}>Nope</button>
			</div>
		{/if}
	</div>
	<button class="take-picture-btn" on:click={takePicture}>Take picture !</button>
</div>

<style>
	.camera {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.camera-video {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
	}

	.taken-picture-container {
		position: absolute;
		background: white;
		box-sizing: border-box;
		border: 5px solid brown;
		max-width: 100vw;
	}

	.taken-picture {
		object-fit: contain;

		max-width: 90vw;
	}

	.take-picture-btn {
		align-self: center;
	}
</style>
