<script>
	import { turnsStore } from '../../Store/game.store';
</script>

{#each $turnsStore as { id, description }, i}
	<p>{description}</p>
{/each}

{#if $turnsStore.length === 0}
	<p>Nothing has happened yet</p>
{/if}

<style>
	ul {
		min-height: 70vh;
	}
</style>
