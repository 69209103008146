<script>
	import { currentTurnStore } from '../../Store/game.store';
</script>

<div>
	<h2>Turn: {$currentTurnStore?.turn}</h2>
	<p>{$currentTurnStore?.name}</p>
</div>

<style lang="postcss">
	div {
		display: flex;
		flex-direction: column;
		align-items: center;

		padding: 0.6em 0;

		h2,
		p {
			margin: 0;
			padding: 0;
		}
	}
</style>
