import { getGifts, getPlayers, getTurnInfo } from '../lib/api';
import { watchGifts, watchPlayers, watchTurns } from '../lib/websockets';

import { writable } from 'svelte/store';

export const giftStore = writable([]);
export const playerStore = writable([]);
export const turnsStore = writable([]);
export const currentTurnStore = writable(null);
export const gameStore = writable({});

import { giftMap, turnMap, playerMap, currentTurnMap } from '../Store/maps';

export const updateGifts = async (gifts) => {
	giftStore.update((g) => (g = gifts.map(giftMap)));
};

export const updatePlayers = async (players) => {
	playerStore.update((p) => (p = players.map(playerMap)));
};

export const updateTurns = async (turns) => {
	turnsStore.update((p) => (p = turns.turns.map(turnMap)));
	currentTurnStore.update((p) => (p = currentTurnMap(turns)));
};

const retrieveGifts = async (gameId) => {
	const result = await getGifts(gameId);
	updateGifts(result);
};

const retrievePlayers = async (gameId) => {
	const result = await getPlayers(gameId);
	updatePlayers(result);
};
const retrieveTurnInfo = async (gameId) => {
	const result = await getTurnInfo(gameId);
	updateTurns(result);
};

export const initGame = async (gameId) => {
	await retrievePlayers(gameId);
	await retrieveTurnInfo(gameId);
	await retrieveGifts(gameId);

	watchGifts(updateGifts, gameId);
	watchPlayers(updatePlayers, gameId);
	watchTurns(updateTurns, gameId);

	gameStore.update((g) => (g = { id: gameId }));
};

export default {
	subscribe: giftStore.subscribe,
	retrieveGifts
	//   sendMessage,
};
