<script>
	import { openGift } from '../../lib/api';
	import { gameStore } from '../../Store/game.store';
	import { currentTurnStore } from '../../Store/game.store';
	export let giftName = '';

	async function openGiftHandler() {
		try {
			await openGift($gameStore?.id, giftName, $currentTurnStore?.id);
		} catch (err) {
			console.error(err);
		}
	}
</script>

<form on:submit|preventDefault={openGiftHandler}>
	<input type="text" name="name" bind:value={giftName} />
	<button type="submit">Open</button>
</form>
