<script>
	import { giftStore, currentTurnStore, gameStore } from '../../Store/game.store';
	import { stealGift } from '../../lib/api';

	export let giftToSteal = '';
	async function stealHandler() {
		if (!giftToSteal) {
			return;
		}

		await stealGift($gameStore?.id, giftToSteal, $currentTurnStore?.id);
		giftToSteal = '';
	}
</script>

<form on:submit|preventDefault={stealHandler}>
	<select bind:value={giftToSteal}>
		<option value="">-- Gift --</option>
		{#each $giftStore.filter((g) => g.stealCount < 3) as { id, description }, i}
			<option value={id}>{description}</option>
		{/each}
	</select>
	<button type="submit">Steal</button>
</form>
