<script>
	import { Router, Link, Route } from 'svelte-navigator';
	import Enter from './Enter.svelte';
	import View from './View.svelte';
	import Register from './Register/Register.svelte';
	import Admin from './Admin/index.svelte';
	import GiftPicture from './Admin/GiftPicture.svelte';
</script>

<Router>
	<Route path="/">
		<Enter />
	</Route>
	<Route path="/game/:id" component={View} />
	<Route path="/game/:id/register" component={Register} />
	<Route path="/admin/:id" component={Admin} />
	<Route path="/admin/:id/gift/:giftId/picture" component={GiftPicture} />
</Router>
