<script>
	import { onMount } from 'svelte';
	import { initGame } from '../../Store/game.store';

	import GiftList from './GiftList.svelte';
	import CurrentTurn from '../Turns/Current.svelte';
	import OpenGift from '../Admin/OpenGift.svelte';
	import UndoTurn from '../Admin/UndoTurn.svelte';
	import StealGift from '../Admin/Steal.svelte';
	export let id;
	export let show = 'gifts';

	function updateShow(setShow) {
		show = setShow;
	}

	onMount(() => {
		initGame(id);
	});
</script>

<h2>Admin</h2>
<OpenGift />
<StealGift />
<UndoTurn />
<CurrentTurn />
<GiftList />
