<script>
	import { undoTurn } from '../../lib/api';
	import { gameStore } from '../../Store/game.store';

	async function undoHandler() {
		await undoTurn($gameStore?.id);
	}
</script>

<button on:click|preventDefault={undoHandler}>Undo</button>
