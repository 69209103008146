<script>
  export let gameName = "";
  function goToGame() {
    window.location.href = `/game/${gameName}`;
  }
</script>

<form on:submit|preventDefault={goToGame}>
  <input bind:value={gameName} type="text" id="game" placeholder="What game?" />
  <button id="join">Join</button>
</form>
