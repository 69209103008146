<script>
	import { onMount } from 'svelte';
	import { initGame } from '../../Store/game.store';

	import RegisterForm from './Form.svelte';
	import Roster from './Roster.svelte';

	export let id;

	onMount(() => {
		initGame(id);
	});
</script>

<div class="register-container">
	<div class="roster">
		<h2>Roster</h2>
		<Roster />
	</div>
	<div class="register">
		<h1>Register<a href={`/game/${id}`}>Play</a></h1>

		<RegisterForm {id} />
	</div>
</div>

<style lang="postcss">
	.register-container {
		display: grid;
		grid-auto-flow: dense;
		grid-template-rows: 1fr;

		h1 {
			a {
				font-size: 0.5em;
				font-weight: 300;
				display: flex;
				justify-content: center;
			}
		}

		.register {
			grid-row: 0;
		}
		.roster {
			grid-row: -1;
		}
	}
	@media (--big-viewport) {
		.register-container {
			grid-template-columns: 200px 1fr;
			.register {
				grid-row: auto;
			}
			.roster {
				grid-row: auto;
			}
		}
	}
</style>
