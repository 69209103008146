export const giftMap = (g) => {
	return {
		id: g._id,
		description: g.description,
		imageUrl: g.imageUrl,
		images: g.images,
		openedBy: g.openedBy,
		owner: g.owner,
		stolenBy: g.stolenBy,
		stealCount: g.stolenBy.length
	};
};

export const turnMap = (t) => {
	return {
		id: t._id,
		description: t.description,
		action: t.action
	};
};

export const playerMap = (p) => {
	return { id: p._id, name: p.nickname, turn: p.turn };
};

export const currentTurnMap = (turns) => {
	return {
		id: turns.current._id,
		imageUrl: turns.current.imageUrl,
		name: turns.current.nickname,
		turn: turns.current.turn
	};
};
