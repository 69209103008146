<script>
	import './tailwind.css';
	import Game from './Game/index.svelte';
	import Header from './Layout/Header.svelte';
	import './tailwind.css';
</script>

<main>
	<Header />
	<Game />
</main>

<style lang="postcss">
	main {
		text-align: center;
		padding: 1em;
		margin: 0 auto;

		h1 {
			color: #ff3e00;
			text-transform: uppercase;
			font-size: 2em;
			font-weight: 100;
			@apply font-bold;
		}
	}

	@media (--big-viewport) {
		main {
			max-width: none;
		}
	}
</style>
