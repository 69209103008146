<script>
	import { giftStore, gameStore } from '../../Store/game.store';
	import { fade } from 'svelte/transition';

	import Thumbnail from '../Shared/Thumbnail.svelte';

	function getGiftClass(stealCount) {
		if (stealCount === 3) {
			return 'gift -gone';
		}
		return 'gift';
	}
</script>

<ul>
	{#each $giftStore as { id, description, imageUrl, owner, stealCount }, i}
		<li class={getGiftClass(stealCount)} transition:fade={{ duration: 500 }}>
			<Thumbnail {imageUrl} alt={description} />
			<div>
				<a href={`/admin/${$gameStore?.id}/gift/${id}/picture`}>{description}</a>
				<div>
					<p>{owner?.nickname} has it</p>
					<p>Steals: {stealCount}</p>
				</div>
			</div>
		</li>
	{/each}
</ul>

{#if $giftStore.length === 0}
	<p>Open something!</p>
{/if}

<style>
	ul {
		min-height: 70vh;
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-end;
		li {
			margin: 2em;
			display: flex;
			justify-content: center;
		}

		a {
			padding: 2em;
			font-size: 1em;
			font-weight: 700;
		}
		.gift.-gone {
			background: #eee;
		}
	}
</style>
