<script>
  import { registerPlayer } from "../../lib/api";
  export let id;
  export let userName;

  async function registerHandler() {
    if (userName && userName.length > 0) {
      try {
        await registerPlayer({ nickname: userName }, id);
        userName = "";
      } catch (e) {
        console.error(e);
      }
    }
  }
</script>

<form on:submit|preventDefault={registerHandler}>
  <input type="text" bind:value={userName} />
  <button>Go</button>
</form>
