<script>
	import { playerStore, currentTurnStore } from '../../Store/game.store';

	function getTurnClasses(turn) {
		const classes = [];
		if ($currentTurnStore?.turn > turn) {
			classes.push('-past');
		}

		if ($currentTurnStore?.turn === turn) {
			classes.push('-current');
		}

		return classes.join(' ');
	}
</script>

<ul>
	{#each $playerStore as { id, name, turn }, i}
		<li class={getTurnClasses(turn)}>
			<div class="turn">{turn}</div>
			{name}
		</li>
	{/each}
</ul>

{#if $playerStore.length === 0}
	<p>A person or two should register</p>
{/if}

<style>
	ul {
		min-height: 70vh;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		li {
			text-align: left;
			width: 70vw;
			display: flex;
			margin: 1em;
			align-items: center;
			.turn {
				padding: 1em;

				border: 1px solid red;
				color: red;
				margin-right: 1em;
			}

			&.-current {
				.turn {
					border: 2px solid green;
					color: green;
				}
			}

			&.-past {
				.turn {
					border: 1px solid gray;
					color: gray;
				}
			}
		}
	}
</style>
